<template>
	<div class="main-wrapper">
		<h4 class="mt-2 mb-5">
			General settings
		</h4>
		<CRow v-if="isUploadLogoAllowed" class="form-group form-group-image">
			<CCol>
				<div class="label mb-2">
					Channel logo
				</div>
				<div class="d-flex" :class="{ 'is-invalid': $v.logo.$error }">
					<div class="image-link">
						<a
							href="#"
							@click.prevent="handleClickLogo"
						>
							<div v-if="!logo" :class="{ 'image-placeholder': !logo }">
								<img src="/assets/images/product-placeholder.svg" alt="Add image" class="mb-1">
								Add image
							</div>
							<img
								v-else
								:src="logo.imageUrls.thumbnail"
								class="image-preview"
								alt=""
							>
						</a>
						<CButton
							v-if="logo"
							class="btn-delete-image"
							@click="handleDeleteLogo"
						>
							<CIcon name="cil-trash" />
						</CButton>
					</div>
					<ul class="image-helper pl-4">
						<li>- Recommended resolution W64 x H64 px</li>
						<li>- Maximum upload size per file is 10MB</li>
						<li>- Supported PNG, JPG, JPEG, GIF</li>
					</ul>
				</div>
				<div class="form-group-image-error invalid-feedback mb-3">
					{{ $t('global.error.required') }}
				</div>
			</CCol>
		</CRow>
		<CRow class="form-group">
			<CCol>
				<div class="label">
					Status
				</div>
			</CCol>
			<CCol md="3">
				<div class="form-group-switch">
					<CSwitch
						:checked.sync="isActive"
						variant="3d"
						size="sm"
						class="switch-custom"
						color="success"
					/>
					<span class="typo-body-2">
						{{ isActive ? 'Active' : 'Inactive' }}
					</span>
				</div>
			</CCol>
		</CRow>
		<CRow class="form-group no-gutters align-items-center">
			<CCol>
				<div class="label">
					Expires in*
				</div>
			</CCol>
			<CCol md="3">
				<div class="input-group">
					<BaseInputNumber
						v-model="$v.expiryPeriodHr.$model"
						:is-valid="!$v.expiryPeriodHr.$error"
						invalid-feedback="Hour must be greater than 0"
						class="input-expiry"
						text-align="left"
						append-text="hr"
						placeholder="0"
					/>
				</div>
			</CCol>
		</CRow>
		<CRow v-if="!isShowRepaymentAllowed && !(isKTCForeverGateway || isKTCCCForeverGateway)" class="form-group">
			<CCol>
				<div class="label">
					Allow reselect payment
				</div>
			</CCol>
			<CCol md="3">
				<div class="form-group-switch">
					<CSwitch
						:checked.sync="isRepaymentAllowed"
						variant="3d"
						size="sm"
						class="switch-custom"
						color="success"
					/>
					<span class="typo-body-2">
						{{ isRepaymentAllowed ? 'Active' : 'Inactive' }}
					</span>
				</div>
			</CCol>
		</CRow>
		<CRow v-if="!isPaymentGroup && !isInstallmentType && !isScbEasy" class="form-group no-gutters align-items-center">
			<CCol>
				<div class="label">
					Gateway
				</div>
			</CCol>
			<CCol md="3">
				<div class="input-group">
					<BaseDropDown
						v-model="gateway"
						:options="PAYMENT_GATEWAY_OPTIONS"
						:searchable="false"
						:allow-empty="false"
						class="gateway-options mb-0"
						label="name"
						track-by="value"
						placeholder="Selection"
						test-id="gateway-options"
					/>
				</div>
			</CCol>
		</CRow>
		<template v-if="isKTCForeverGateway">
			<CRow class="form-group no-gutters align-items-center">
				<CCol>
					<div class="label">
						KTC Product ID*
					</div>
				</CCol>
				<CCol md="3">
					<div class="input-group">
						<CInput
							v-model.trim="$v.ktcProductId.$model"
							:is-valid="!$v.ktcProductId.$error && null"
							:invalid-feedback="ktcProductIdErrorMessage"
							placeholder="E.g. 1234"
							class="mb-0 input-product-id"
						/>
					</div>
				</CCol>
			</CRow>
			<CRow class="form-group no-gutters align-items-center">
				<CCol>
					<div class="label">
						Point Ratio*
					</div>
					<span class="title-caption typo-caption color-black-45">Set this payment to using X percent of the total price to subtract the user's point.</span>
				</CCol>
				<CCol md="3">
					<div class="input-group">
						<BaseInputNumber
							v-model.trim="$v.rate.$model"
							:is-valid="!$v.rate.$error"
							:invalid-feedback="rateErrorMessage"
							placeholder="1"
							append-text="%"
							text-align="left"
						/>
					</div>
				</CCol>
			</CRow>
		</template>
		<CRow
			v-if="!isBAYInstallment && !isKTCInstallment"
			:gutters="false"
			align-vertical="center"
			class="form-group"
		>
			<CCol>
				<div class="label">
					Maximum order
				</div>
			</CCol>
			<CCol md="3">
				<BaseInputNumber
					v-model="maxOrder"
					:decimal-scale="2"
					placeholder="0.00"
					prepend-text="฿"
					text-align="right"
				/>
			</CCol>
		</CRow>
		<CRow
			v-if="!isBAYInstallment && !isKTCInstallment"
			:gutters="false"
			align-vertical="center"
			class="form-group"
		>
			<CCol>
				<div class="label">
					Minimum order
				</div>
			</CCol>
			<CCol md="3">
				<BaseInputNumber
					v-model="minOrder"
					:decimal-scale="2"
					placeholder="0.00"
					prepend-text="฿"
					text-align="right"
				/>
			</CCol>
		</CRow>
		<CRow
			v-if="!isBAYInstallment && !isKTCInstallment"
			:gutters="false"
			align-vertical="center"
			class="form-group"
		>
			<CCol>
				<div class="label">
					Available for categories
				</div>
			</CCol>
			<CCol md="3">
				<CButton
					block
					class="transparent"
					color="secondary"
					@click="handleEditCategory"
				>
					Edit categories
				</CButton>
			</CCol>
		</CRow>
		<CRow
			:gutters="false"
			align-vertical="center"
			class="form-group"
		>
			<CCol>
				<div class="label">
					Exclude SKU
				</div>
			</CCol>
			<CCol md="3">
				<CButton
					block
					class="transparent"
					color="secondary"
					@click="$refs['modal-sku'].open()"
				>
					Edit SKU
				</CButton>
			</CCol>
		</CRow>
		<CRow
			:gutters="false"
			align-vertical="center"
			class="form-group"
		>
			<CCol>
				<div class="label">
					Available for customer group
				</div>
				<span class="title-caption typo-caption color-black-45">Set this payment to available for specific customer group.</span>
			</CCol>
			<CCol md="3">
				<CButton
					block
					class="transparent"
					color="secondary"
					@click="$refs['modal-customer-group'].open()"
				>
					Edit group
				</CButton>
			</CCol>
			<CCol md="12">
				<hr class="mt-5 mb-3">
			</CCol>
		</CRow>
		<PaymentSettingInstallment
			v-if="(isInstallmentType && !isOtherBankAgent) || isScbEasy"
			:agent="agent"
			:group-id="groupId"
			:method-id="methodId"
			:plans="installmentPlans"
			:is-b-a-y-bank-agent="isBAYBankAgent"
			:is-k-t-c-bank-agent="isKTCBankAgent"
		/>
		<PaymentSubgroupsChannels
			v-if="showSubGroupsPayment"
			:payment-method-sub-groups="paymentMethodSubGroups"
		/>
		<PaymentBankChannels
			v-if="isOtherBankAgent"
			:is-installment-type="isInstallmentType"
			:channels="paymentChannels"
		/>
		<BrandInstallmentPlan
			v-if="isBAYInstallment"
			:plans="installmentPlanGroups"
		/>
		<KTCInstallmentPlan
			v-if="isKTCInstallment"
		/>
		<KTCForeverPlan
			v-if="isKTCCCForeverGateway"
			:plans="ccForeverPlans"
			:group-id="groupId"
			:method-id="methodId"
		/>
		<CRow
			:gutters="false"
			align-vertical="center"
			class="form-group"
		>
			<CCol md="12">
				<h4 class="mb-4">
					Instruction details
				</h4>
				<CRow class="mt-4">
					<CCol md="12">
						<label>Instructions details (Thai version)</label>
						<BaseRichTextEditor v-model="contentTH" name="contentTH" />
					</CCol>
				</CRow>
				<CRow class="mt-2">
					<CCol md="12">
						<label>Instructions details (English version)</label>
						<BaseRichTextEditor v-model="contentEN" name="contentEN" />
					</CCol>
				</CRow>
				<hr class="mt-4">
			</CCol>
		</CRow>
		<BaseActionPanelStickyFooter
			:disabled-confirm="isUpdating"
			content-class="main-wrapper"
			is-edit
			@onConfirm="handleSubmit"
			@onCancel="$router.push({ name: 'PaymentList'})"
		/>
		<ModalSkuList
			ref="modal-sku"
			title="Exclude SKUs"
			:sku-list="skuList"
			@onConfirm="handleSKUChange"
		/>
		<ModalCategory
			ref="modal-category"
			:selected-ids="categoryIds"
			title="Available for categories"
			@onCancel="handleCancelCategoryModal"
		/>
		<ModalMediaLibrary
			ref="modal-media-library"
			type="single"
			@onSubmit="handleSubmitLogo"
		/>
		<ModalCustomerGroup
			ref="modal-customer-group"
			title="Edit customer group"
			:list="customerGroups"
			@onConfirm="handleCustomerGroupListChange"
		/>
		<!-- :list="customerGroups" -->
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { required, requiredIf, minValue, maxLength, minLength } from 'vuelidate/lib/validators';
import ModalSkuList from '@/components/ModalSkuList.vue';
import ModalCategory from '@/components/ModalCategory.vue';
import ModalMediaLibrary from '@/components/ModalMediaLibrary.vue';
import ModalCustomerGroup from '@/components/ModalCustomerGroup.vue';
import PaymentSettingInstallment from '@/components/PaymentSettingInstallment.vue';
import BrandInstallmentPlan from '@/components/BrandInstallmentPlan.vue';
import KTCInstallmentPlan from '@/components/KTCInstallmentPlan.vue';
import KTCForeverPlan from '@/components/KTCForeverPlan.vue';
import PaymentBankChannels from '@/components/PaymentBankChannels.vue';
import PaymentSubgroupsChannels from '@/components/PaymentSubgroupsChannels.vue';
import { scrollToTop, priceToAPI, isBAYBankAgent, isKTCBankAgent, parseNumber } from '../assets/js/helpers';
import {
	PAYMENT_GATEWAY_OPTIONS,
	PAYMENT_AGENT_OTHER_BANK,
	PAYMENT_INSTALLMENT_SLUG,
	PAYMENT_BILL_PAYMENT_SLUG,
	PAYMENT_PAY_AT_STORE_SLUG,
	PAYMENT_PAY_ON_PICK_UP_SLUG,
	PAYMENT_COUNTER_PAYMENT_SLUG,
	PAYMENT_COD_SLUG,
	PAYMENT_SCB_EASY_SLUG,
	PAYMENT_GATEWAY,
} from '../enums/payments';

export default {
	name: 'PaymentSettingGeneral',

	validations() {
		return {
			logo: {
				required: requiredIf((vm) => !vm.isPaymentGroup && !vm.isOtherBankAgent),
			},
			expiryPeriodHr: {
				required,
				minValue: minValue(1),
			},
			ktcProductId: {
				required: requiredIf((vm) => vm.isKTCForeverGateway),
				maxLength: maxLength(4),
				minLength: minLength(4),
			},
			rate: {
				required: requiredIf((vm) => vm.isKTCForeverGateway),
				minValue: minValue(1),
			},
		};
	},
	components: {
		ModalSkuList,
		ModalCategory,
		ModalMediaLibrary,
		ModalCustomerGroup,
		PaymentSettingInstallment,
		PaymentBankChannels,
		PaymentSubgroupsChannels,
		BrandInstallmentPlan,
		KTCInstallmentPlan,
		KTCForeverPlan,
	},
	props: {
		data: {
			type: Object,
			default: () => ({}),
		},
		isUpdating: {
			type: Boolean,
			default: false,
		},
		isPaymentGroup: {
			type: Boolean,
			default: false,
		},
		groupId: {
			type: Number,
			default: null,
		},
		groupSlug: {
			type: String,
			default: null,
		},
		methodId: {
			type: Number,
			default: null,
		},
	},
	data() {
		return {
			PAYMENT_AGENT_OTHER_BANK,
			PAYMENT_GATEWAY_OPTIONS,
			PAYMENT_PAY_AT_STORE_SLUG,
			PAYMENT_PAY_ON_PICK_UP_SLUG,
			PAYMENT_COD_SLUG,
			agent: null,
			logo: null,
			name: null,
			group: null,
			gateway: null,
			slug: null,
			isActive: null,
			expiryPeriodHr: null,
			isRepaymentAllowed: null,
			minOrder: null,
			maxOrder: null,
			ktcProductId: null,
			rate: null,
			contentTH: null,
			contentEN: null,
			categories: [],
			skuList: [],
			excludeProducts: [],
			installmentPlans: [],
			installmentPlanGroups: [],
			ccForeverPlans: [],
			paymentChannels: [],
			paymentMethodSubGroups: [],
			customerGroups: [],
		};
	},
	computed: {
		...mapGetters({
			getSelectedCategories: 'categorySelector/getSelectedCategories',
		}),

		categoryIds() {
			return this.categories.map((category) => category.id);
		},
		isInstallmentType() {
			return this.groupSlug === PAYMENT_INSTALLMENT_SLUG;
		},
		showSubGroupsPayment() {
			const allowPaymentMethods = [PAYMENT_BILL_PAYMENT_SLUG, PAYMENT_COUNTER_PAYMENT_SLUG];

			return allowPaymentMethods.includes(this.groupSlug);
		},
		isPayAtStoreType() {
			return this.groupSlug === PAYMENT_PAY_AT_STORE_SLUG;
		},
		isCODType() {
			return this.groupSlug === PAYMENT_COD_SLUG;
		},
		isPayOnPickUpType() {
			return this.groupSlug === PAYMENT_PAY_ON_PICK_UP_SLUG;
		},
		isOtherBankAgent() {
			return this.agent === PAYMENT_AGENT_OTHER_BANK;
		},
		isBAYBankAgent() {
			return isBAYBankAgent(this.agent);
		},
		isKTCBankAgent() {
			return isKTCBankAgent(this.agent);
		},
		isKTCForeverGateway() {
			return this.agent === PAYMENT_GATEWAY.KTC_FOREVER;
		},
		isKTCCCForeverGateway() {
			return this.agent === PAYMENT_GATEWAY.KTC_CC_FOREVER;
		},
		isBAYInstallment() {
			return this.isBAYBankAgent && this.isInstallmentType;
		},
		isKTCInstallment() {
			return this.isKTCBankAgent && this.isInstallmentType;
		},
		customerGroupList() {
			return this.customerGroup.map(({ id, name }) => ({ id, name }));
		},
		isShowRepaymentAllowed() {
			return this.isCODType || this.isPayAtStoreType || this.isPayOnPickUpType;
		},
		isRepaymentAllowedWithPaymentMethodCondition() {
			return this.isRepaymentAllowed && !this.isShowRepaymentAllowed;
		},
		isUploadLogoAllowed() {
			return (
				!this.isPaymentGroup
				&& !this.isOtherBankAgent
			);
		},
		isScbEasy() {
			return this.groupSlug === PAYMENT_SCB_EASY_SLUG;
		},
		getInstallmentPlans() {
			if (this.isKTCInstallment) {
				return this.data.installmentPlans.filter((plan) => !plan.customAttributes?.absorptionType);
			}

			return this.data.installmentPlans;
		},
		getInstallmentPlanGroups() {
			if (this.isKTCInstallment) {
				return this.data.installmentPlans.filter((plan) => plan.customAttributes?.absorptionType);
			}

			return this.data.installmentPlanGroups;
		},
		ktcProductIdErrorMessage() {
			if (!this.$v.ktcProductId.maxLength || !this.$v.ktcProductId.minLength) {
				return `Must have 4 character`;
			} else if (!this.$v.ktcProductId.required) {
				return this.$t('global.error.required');
			}

			return null;
		},
		rateErrorMessage() {
			if (!this.$v.rate.minValue) {
				return 'Rate must be greater than 0';
			} else if (!this.$v.rate.required) {
				return this.$t('global.error.required');
			}

			return null;
		},
	},
	watch: {
		data: {
			deep: true,
			immediate: true,
			handler() {
				this.logo = this.data.logo || null;
				this.name = this.data.name;
				this.agent = this.data.agent;
				this.group = this.data.group;
				this.slug = this.data.slug;
				this.isActive = this.data.isActive;
				this.expiryPeriodHr = this.data.expiryPeriodHr;
				this.isRepaymentAllowed = this.data.isRepaymentAllowed;
				this.excludeProducts = this.data.excludeProducts;
				this.skuList = this.excludeProducts.map((item) => item.sku);
				this.minOrder = this.data.minOrder;
				this.maxOrder = this.data.maxOrder;
				this.rate = this.data.rate;
				this.ktcProductId = this.data.ktcProductId;
				this.contentTH = this.data.contentTH || '';
				this.contentEN = this.data.contentEN || '';
				this.categories = this.data.categories;
				this.gateway = PAYMENT_GATEWAY_OPTIONS.find((gateway) => gateway.value === this.data.gateway);
				this.installmentPlans = this.getInstallmentPlans;
				this.installmentPlanGroups = this.getInstallmentPlanGroups;
				this.ccForeverPlans = this.data.ccForeverPlans;
				this.paymentChannels = this.data.paymentChannels;
				this.paymentMethodSubGroups = this.data.paymentMethodSubGroups;
				this.customerGroups = this.data.customerGroups;

				this.preSelectCategories(this.categoryIds);
			},
		},
	},
	methods: {
		...mapActions({
			preSelectCategories: 'categorySelector/preSelectCategories',
		}),
		handleUpdateStatus(value) {
			this.isActive = value;
		},
		handleUpdateIsRepaymentAllowed(value) {
			this.isRepaymentAllowed = value;
		},
		handleCancelCategoryModal() {
			// Reset category data when user close/cancel category modal
			this.preSelectCategories(this.categoryIds);
		},
		handleEditCategory() {
			this.$refs['modal-category'].open();
		},
		handleClickLogo() {
			this.$refs['modal-media-library'].open();
		},
		handleSubmitLogo(files) {
			this.logo = files[0];
		},
		handleDeleteLogo() {
			this.logo = null;
		},
		handleSKUChange(skus = []) {
			this.skuList = [...skus];
		},
		handleCustomerGroupListChange(list) {
			if (!list || !Array.isArray(list)) {
				return;
			}

			this.customerGroups = list;
		},
		handleSubmit() {
			this.$v.$touch();

			if (this.$v.$invalid) {
				scrollToTop();
				return;
			}

			let params = {
				name: this.name,
				slug: this.slug,
				group: '',
				is_active: this.isActive ? 1 : 0,
				expiry_period_hr: this.expiryPeriodHr,
				is_repayment_allowed: this.isRepaymentAllowedWithPaymentMethodCondition ? 1 : 0,
				// No need to send min-max order for BAY installment
				min_order: this.minOrder && !this.isBAYInstallment ? priceToAPI(this.minOrder) : null,
				max_order: this.maxOrder && !this.isBAYInstallment ? priceToAPI(this.maxOrder) : null,
				content_th: this.contentTH,
				content_en: this.contentEN,
				category_ids: this.getSelectedCategories.map((category) => category.id),
				customer_group_ids: this.customerGroups.map(({ id }) => id),
				excluded_product_skus: this.skuList,
				logo_file_id: this.logo ? this.logo.id : null,
			};

			// Do not include gateway for SCB Easy payment method
			if (!this.isScbEasy) {
				params = {
					...params,
					gateway: this.gateway?.value ?? null,
				};
			}

			// Do include rate and KTC product ID for KTC gateway payment
			if (this.isKTCForeverGateway) {
				params = {
					...params,
					rate: this.rate ? parseNumber(this.rate) : null,
					ktc_product_id: this.ktcProductId,
				};
			}

			this.$emit('onSubmit', params);
		},
	},
};
</script>

<style lang="scss" scoped>
	::v-deep .input-product-id {
		.form-control {
			text-align: right;
		}
	}

	.form-group {
		margin-bottom: rem(32);
	}

	.image-placeholder {
		@include typo-helper-text;

		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		width: rem(78);
		height: rem(78);
		background-color: $color-gray-100;
		border: 1px solid $color-gray-300;
		border-radius: 2px;
		color: $color-black-45;

		img {
			width: rem(32);
			height: rem(32);
		}
	}

	.image-helper {
		list-style: none;
		margin: 0;

		li {
			@include typo-body-2;

			color: $color-black-45;
		}
	}

	.image-link {
		position: relative;
		display: block;

		a {
			&:hover {
				text-decoration: none;
			}
		}
	}

	.image-preview {
		width: rem(78);
		height: rem(78);
		border: 1px solid $color-gray-300;
		border-radius: 2px;
		object-fit: contain;
	}

	.form-group-image {
		a {
			text-decoration: none;
		}

		.is-invalid {
			.image-placeholder,
			.image-preview {
				border-color: $color-alert;
			}
		}
	}

	.form-group-image-error {
		display: block;
		visibility: hidden;
	}

	.is-invalid ~ .invalid-feedback {
		visibility: visible;
	}

	.type-secondary {
		.image-placeholder,
		.image-preview {
			width: rem(234);
			height: rem(64);
		}
	}

	.btn-delete-image {
		position: absolute;
		right: rem(8);
		bottom: rem(8);
		width: rem(20);
		height: rem(20);
		box-shadow: none;
		padding: 0;
		border-radius: 2px;
		background-color: $color-black-45;

		svg {
			width: rem(12) !important;
			color: $color-white;
		}
	}

	.gateway-options {
		width: rem(150);
	}
</style>
