<template>
	<CRow
		:gutters="false"
	>
		<CCol md="8">
			<h4>CC Forever plan</h4>
			<span class="typo-body-2 color-black-45">Create CC Forever plans for campaign that have different cc forever plans from default plans.</span>
		</CCol>
		<CCol md="4" class="text-right">
			<router-link
				:to="{
					name: 'KTCForeverPlanCreate',
					params: { id: $route.params.id, methodId: $route.params.methodId }
				}"
				class="btn btn-secondary"
			>
				<CIcon class="mr-2" name="cil-plus" /> Create plan
			</router-link>
		</CCol>
		<CCol md="12">
			<BaseNoItemBanner
				v-if="!plans.length"
				class="mt-4"
				text="There are no forever plan for any campaigns."
			/>
			<BaseTableKTCForever
				v-else
				router-edit-name="KTCForeverPlanEdit"
				:plans="plans"
				:group-id="groupId"
				:method-id="methodId"
			/>
			<hr class="my-5">
		</CCol>
	</CRow>
</template>

<script>
import BaseTableKTCForever from '@/components/BaseTableKTCForever.vue';

export default {
	name: 'KTCForeverPlan',

	components: {
		BaseTableKTCForever,
	},

	props: {
		groupId: {
			type: Number,
			default: null,
		},
		methodId: {
			type: Number,
			default: null,
		},
		channelId: {
			type: Number,
			default: null,
		},
		plans: {
			type: Array,
			default: () => [],
		},
	},
};
</script>
