var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{staticClass:"table-channels w-100 typo-body-2 mt-4"},[_vm._m(0),_c('tbody',_vm._l((_vm.plans),function(plan){return _c('tr',{key:plan.id},[_c('td',{staticClass:"col-name"},[_c('span',[_vm._v(_vm._s(plan.name))])]),_c('td',[_vm._v(_vm._s(_vm._f("numberFormat")(plan.installmentPlanCount))+" Plan(s)")]),_c('td',[_vm._v(_vm._s(_vm._f("numberFormat")(plan.availableProductCount))+" SKU(s)")]),_c('td',{staticClass:"text-right"},[_c('router-link',{staticClass:"icon-edit-channel",attrs:{"to":{
						name: 'BrandInstallmentPlanEdit',
						params: {
							id: _vm.$route.params.id,
							methodId: _vm.$route.params.methodId,
							brandId: plan.id,
						}
					}}},[_c('CIcon',{staticClass:"mr-2",attrs:{"name":"cil-pencil"}})],1)],1)])}),0)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',[_vm._v("Plan name")]),_c('th',{staticClass:"w-25"},[_vm._v("Installment plans")]),_c('th',{staticClass:"w-25"},[_vm._v("Available SKUs")]),_c('th')])])}]

export { render, staticRenderFns }