<template>
	<table class="table-ktc-forever w-100 typo-body-2 mt-4">
		<thead>
			<tr>
				<th>Name</th>
				<th>KTC Product ID</th>
				<th>Point ratio</th>
				<th>Discount Percent</th>
				<th></th>
			</tr>
		</thead>
		<tbody>
			<tr v-for="plan in plans" :key="plan.id">
				<td>{{ plan.name }}</td>
				<td>{{ plan.ktcProductId }}</td>
				<td>{{ plan.rate }}%</td>
				<td>{{ plan.discountPercent }}%</td>
				<td>
					<router-link
						:to="{
							name: routerEditName,
							params: {
								id: groupId,
								methodId: methodId,
								planId: plan.id,
							}
						}"
						class="icon-edit-plan"
					>
						<CIcon class="mr-2" name="cil-pencil" />
					</router-link>
				</td>
			</tr>
		</tbody>
	</table>
</template>

<script>
export default {
	name: 'BaseTableKTCCCForever',

	props: {
		groupId: {
			type: [String, Number],
			default: null,
		},
		methodId: {
			type: [String, Number],
			default: null,
		},
		plans: {
			type: Array,
			default: () => [],
		},
		routerEditName: {
			type: String,
			default: null,
		},
	},
};
</script>

<style lang="scss" scoped>
	.table-ktc-forever {
		color: $color-black-90;

		tbody {
			box-shadow: 0 0 0 1px $color-gray-400;
			border-radius: 4px;
		}

		td,
		th {
			padding: rem(12);
			vertical-align: top;

			&:first-child {
				width: 50%;
			}

			&:nth-child(2) {
				width: 20%;
				word-break: break-word;
			}

			&:nth-child(3) {
				width: 20%;
				word-break: break-word;
			}
		}

		tr {
			> td, > th {
				padding-left: rem(16);
			}
		}
	}

	.icon-edit-plan {
		@include typo-body-2;

		cursor: pointer;
		color: $color-black-45;
		font-weight: 500;

		&:hover {
			color: $color-orange;
		}
	}
</style>
