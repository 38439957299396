var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{staticClass:"table-channels w-100 typo-body-2 mt-4"},[_vm._m(0),_c('tbody',_vm._l((_vm.channelsTable),function(channel){return _c('tr',{key:channel.id},[_c('td',[_c('div',{staticClass:"d-flex channel-info"},[_c('img',{staticClass:"thumbnail-40 mr-3",attrs:{"src":channel.thumbnail,"alt":""}}),_c('div',{staticClass:"channel-name"},[_c('div',{staticClass:"channel-name-en typo-body-2 color-black-90"},[_vm._v(" "+_vm._s(channel.nameEN)+" ")]),_c('div',{staticClass:"channel-name-th color-black-45"},[_vm._v(" "+_vm._s(channel.nameTH)+" ")])])])]),_c('td',[(!channel.installmentPlans.length)?_c('router-link',{attrs:{"to":{
						name: 'InstallmentPlanChannelCreate',
						params: {
							id: _vm.$route.params.id,
							methodId: _vm.$route.params.methodId,
							channelId: channel.id,
						}
					}}},[_vm._v(" Create Plan ")]):_c('router-link',{attrs:{"to":{
						name: 'InstallmentPlanChannel',
						params: {
							id: _vm.$route.params.id,
							methodId: _vm.$route.params.methodId,
							channelId: channel.id,
						}
					}}},[_vm._v(" "+_vm._s(channel.installmentPlans.length)+" plan(s) ")])],1),_c('td',[(channel.totalSku <= 0)?[_vm._v(" - ")]:_c('router-link',{attrs:{"to":{
						name: 'InstallmentPlanChannel',
						params: {
							id: _vm.$route.params.id,
							methodId: _vm.$route.params.methodId,
							channelId: channel.id,
						}
					}}},[_vm._v(" "+_vm._s(channel.totalSku)+" SKU(s) ")])],2),_c('td',[_c('CBadge',{staticClass:"badge-status",class:channel.isActive ? 'badge-active' : 'badge-inactive'},[_vm._v(" "+_vm._s(channel.isActive ? 'Active' : 'Inactive')+" ")])],1),_c('td',[_c('router-link',{staticClass:"icon-edit-channel",attrs:{"to":{
						name: 'PaymentCreditCardBankChannelEdit',
						params: { methodId: _vm.$route.params.methodId, channelId: channel.id }
					}}},[_c('CIcon',{staticClass:"mr-2",attrs:{"name":"cil-pencil"}})],1)],1)])}),0)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{staticClass:"col-name"},[_vm._v(" Channels ")]),_c('th',{staticClass:"col-installment"},[_vm._v(" Installment plan ")]),_c('th',{staticClass:"col-sku"},[_vm._v(" Available SKU ")]),_c('th',{staticClass:"col-status"},[_vm._v(" Status ")]),_c('th')])])}]

export { render, staticRenderFns }