<template>
	<table class="table-channels w-100 typo-body-2 mt-4">
		<thead>
			<tr>
				<th>Plan name</th>
				<th class="w-25">Installment plans</th>
				<th class="w-25">Available SKUs</th>
				<th></th>
			</tr>
		</thead>
		<tbody>
			<tr v-for="plan in plans" :key="plan.id">
				<td class="col-name"><span>{{ plan.name }}</span></td>
				<td>{{ plan.installmentPlanCount | numberFormat }} Plan(s)</td>
				<td>{{ plan.availableProductCount | numberFormat }} SKU(s)</td>
				<td class="text-right">
					<router-link
						:to="{
							name: 'BrandInstallmentPlanEdit',
							params: {
								id: $route.params.id,
								methodId: $route.params.methodId,
								brandId: plan.id,
							}
						}"
						class="icon-edit-channel"
					>
						<CIcon class="mr-2" name="cil-pencil" />
					</router-link>
				</td>
			</tr>
		</tbody>
	</table>
</template>

<script>
export default {
	name: 'BrandCampaignInstallmentTable',

	props: {
		plans: {
			type: Array,
			default: () => [],
		},
	},
};
</script>

<style lang="scss" scoped>
	.col-name {
		position: relative;
		width: 35%;

		&::before {
			content: "&nbsp;";
			visibility: hidden;
		}

		span {
			position: absolute;
			left: rem(16);
			right: 0;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
</style>