<template>
	<table class="table-channels w-100 typo-body-2 mt-4">
		<thead>
			<tr>
				<th class="col-name">
					Channels
				</th>
				<th class="col-installment">
					Installment plan
				</th>
				<th class="col-sku">
					Available SKU
				</th>
				<th class="col-status">
					Status
				</th>
				<th></th>
			</tr>
		</thead>
		<tbody>
			<tr v-for="channel in channelsTable" :key="channel.id">
				<td>
					<div class="d-flex channel-info">
						<img class="thumbnail-40 mr-3" :src="channel.thumbnail" alt="">
						<div class="channel-name">
							<div class="channel-name-en typo-body-2 color-black-90">
								{{ channel.nameEN }}
							</div>
							<div class="channel-name-th color-black-45">
								{{ channel.nameTH }}
							</div>
						</div>
					</div>
				</td>
				<td>
					<router-link
						v-if="!channel.installmentPlans.length"
						:to="{
							name: 'InstallmentPlanChannelCreate',
							params: {
								id: $route.params.id,
								methodId: $route.params.methodId,
								channelId: channel.id,
							}
						}"
					>
						Create Plan
					</router-link>
					<router-link
						v-else
						:to="{
							name: 'InstallmentPlanChannel',
							params: {
								id: $route.params.id,
								methodId: $route.params.methodId,
								channelId: channel.id,
							}
						}"
					>
						{{ channel.installmentPlans.length }} plan(s)
					</router-link>
				</td>
				<td>
					<template v-if="channel.totalSku <= 0">
						-
					</template>
					<router-link
						v-else
						:to="{
							name: 'InstallmentPlanChannel',
							params: {
								id: $route.params.id,
								methodId: $route.params.methodId,
								channelId: channel.id,
							}
						}"
					>
						{{ channel.totalSku }} SKU(s)
					</router-link>
				</td>
				<td>
					<CBadge class="badge-status" :class="channel.isActive ? 'badge-active' : 'badge-inactive' ">
						{{ channel.isActive ? 'Active' : 'Inactive' }}
					</CBadge>
				</td>
				<td>
					<router-link
						:to="{
							name: 'PaymentCreditCardBankChannelEdit',
							params: { methodId: $route.params.methodId, channelId: channel.id }
						}"
						class="icon-edit-channel"
					>
						<CIcon class="mr-2" name="cil-pencil" />
					</router-link>
				</td>
			</tr>
		</tbody>
	</table>
</template>

<script>

export default {
	name: 'PaymentBankChannelsInstallmentTable',

	props: {
		channels: {
			type: Array,
			default: () => [],
		},
	},
	computed: {
		channelsTable() {
			return this.channels.map((channel) => ({
				...channel,
				totalSku: this.totalSku(channel.installmentPlans),
			}));
		},
	},
	methods: {
		totalSku(plans = []) {
			return plans.reduce((sum, current) => sum + current.availableSkus, 0);
		},
	},
};
</script>

<style lang="scss" scoped>
	.col {
		// .col-name
		&-name {
			width: 40%;
		}

		// .col-status
		&-status {
			width: 10%;
		}

		// .col-installment
		&-installment {
			width: 25%;
		}

		// .col-sku
		&-sku {
			width: 25%;
		}
	}
</style>
