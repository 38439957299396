<template>
	<CRow
		:gutters="false"
	>
		<CCol md="8" class="mb-5">
			<h4>Merchant and Supplier installment plan</h4>
			<span class="typo-body-2 color-black-45">Create installment plans for campaign that have different installment plans from default plans.</span>
		</CCol>
		<CCol md="4" class="text-right">
			<router-link
				:to="{ name: 'KTCInstallmentPlanList'}"
				class="btn btn-secondary"
			>
				View all plans
			</router-link>
		</CCol>
	</CRow>
</template>

<script>
export default {
	name: 'KTCInstallmentPlan',
};
</script>
