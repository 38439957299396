<template>
	<CRow
		:gutters="false"
		align-vertical="center"
		class="form-group"
	>
		<CCol md="12">
			<h4>
				Channels
			</h4>
			<div class="typo-body-2 color-black-45 mb-3">
				<span>
					The channels below will be shown underneath the other banks options in the checkout process. Each channel will use the same general setting.
				</span>
			</div>
		</CCol>
		<CCol
			v-for="group in paymentMethodSubGroups"
			:key="group.id"
			md="12"
		>
			<h6>{{ group.nameEN }}</h6>
			<BaseNoItemBanner
				v-if="!group.paymentChannels.length"
				class="mt-4"
				text="There are no channels."
			/>
			<table v-else class="table-channels w-100 typo-body-2 mb-5">
				<thead>
					<tr>
						<th class="col-name">
							Channels
						</th>
						<th class="col-status">
							Status
						</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="channel in group.paymentChannels" :key="channel.id">
						<td>
							<div class="d-flex channel-info">
								<img class="thumbnail-40 mr-3" :src="channel.thumbnail" alt="">
								<div class="channel-name">
									<div class="channel-name-en typo-body-2 color-black-90">
										{{ channel.nameEN }}
									</div>
									<div class="channel-name-th color-black-45">
										{{ channel.nameTH }}
									</div>
								</div>
							</div>
						</td>
						<td>
							<CBadge class="badge-status" :class="channel.isActive ? 'badge-active' : 'badge-inactive' ">
								{{ channel.isActive ? 'Active' : 'Inactive' }}
							</CBadge>
						</td>
						<td>
							<router-link
								:to="{
									name: 'PaymentSubgroupsChannelEdit',
									params: { id: $route.params.id, methodId: channel.id }
								}"
								class="icon-edit-channel"
							>
								<CIcon class="mr-2" name="cil-pencil" />
							</router-link>
						</td>
					</tr>
				</tbody>
			</table>
		</CCol>
		<CCol md="12">
			<hr class="mt-1 mb-4">
		</CCol>
	</CRow>
</template>

<script>

export default {
	name: 'PaymentSubgroupsChannels',

	props: {
		paymentMethodSubGroups: {
			type: Array,
			default: () => [],
		},
	},
};
</script>

<style lang="scss" scoped>
	.table-channels {
		color: $color-black-90;

		tbody {
			box-shadow: 0 0 0 1px $color-gray-400;
			border-radius: 4px;
		}

		td,
		th {
			padding: rem(12);
		}

		tr {
			> td, > th {
				padding-left: rem(16);
			}
		}

		.col {
			// .col-name
			&-name {
				width: 80%;
			}

			// .col-status
			&-status {
				width: 15%;
			}
		}
	}

	.channel-name-th {
		font-size: $font-size-helper-text;
	}

	.icon-edit-channel {
		@include typo-body-2;

		cursor: pointer;
		color: $color-black-45;
		font-weight: $font-weight-semi-bold;

		&:hover {
			color: $color-orange;
		}
	}
</style>
