<template>
	<table class="table-channels w-100 typo-body-2 mt-4">
		<thead>
			<tr>
				<th class="col-name">
					Channels
				</th>
				<th class="col-status">
					Status
				</th>
				<th></th>
			</tr>
		</thead>
		<tbody>
			<tr v-for="channel in channels" :key="channel.id">
				<td>
					<div class="d-flex channel-info">
						<img class="thumbnail-40 mr-3" :src="channel.thumbnail" alt="">
						<div class="channel-name">
							<div class="channel-name-en typo-body-2 color-black-90">
								{{ channel.nameEN }}
							</div>
							<div class="channel-name-th color-black-45">
								{{ channel.nameTH }}
							</div>
						</div>
					</div>
				</td>
				<td>
					<CBadge class="badge-status" :class="channel.isActive ? 'badge-active' : 'badge-inactive' ">
						{{ channel.isActive ? 'Active' : 'Inactive' }}
					</CBadge>
				</td>
				<td>
					<router-link
						:to="{
							name: 'PaymentCreditCardBankChannelEdit',
							params: { methodId: $route.params.methodId, channelId: channel.id }
						}"
						class="icon-edit-channel"
					>
						<CIcon class="mr-2" name="cil-pencil" />
					</router-link>
				</td>
			</tr>
		</tbody>
	</table>
</template>

<script>

export default {
	name: 'PaymentBankChannelsTable',

	props: {
		channels: {
			type: Array,
			default: () => [],
		},
	},
};
</script>

<style lang="scss" scoped>
	.col {
		// .col-name
		&-name {
			width: 80%;
		}

		// .col-status
		&-status {
			width: 15%;
		}
	}
</style>
