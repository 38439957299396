<template>
	<CRow
		:gutters="false"
	>
		<CCol md="8">
			<h4>Campaign installment plan</h4>
			<span class="typo-body-2 color-black-45">Create installment plans for campaign that have different installment plans from default plans.</span>
		</CCol>
		<CCol md="4" class="text-right">
			<router-link
				:to="{
					name: 'BrandInstallmentPlanCreate',
					params: { id: $route.params.id, methodId: $route.params.methodId }
				}"
				class="btn btn-secondary"
			>
				<CIcon class="mr-2" name="cil-plus" /> Create plan
			</router-link>
		</CCol>
		<CCol md="12">
			<BaseNoItemBanner
				v-if="!plans.length"
				class="mt-4"
				text="There are no installment plan for any campaigns."
			/>
			<BrandCampaignInstallmentTable
				v-else
				:plans="plans"
			/>
			<hr class="my-5">
		</CCol>
	</CRow>
</template>

<script>
import BrandCampaignInstallmentTable from '@/components/BrandCampaignInstallmentTable.vue';

export default {
	name: 'BrandInstallmentPlan',
	components: {
		BrandCampaignInstallmentTable,
	},
	props: {
		plans: {
			type: Array,
			default: () => [],
		},
	},
};
</script>

<style lang="scss" scoped>
	::v-deep .table-channels {
		color: $color-black-90;

		tbody {
			box-shadow: 0 0 0 1px $color-gray-400;
			border-radius: 4px;
		}

		td,
		th {
			padding: rem(12);
		}

		tr {
			> td, > th {
				padding-left: rem(16);
			}
		}

		.channel-name-th {
			font-size: rem($font-size-helper-text);
		}

		.icon-edit-channel {
			@include typo-body-2;

			cursor: pointer;
			color: $color-black-45;
			font-weight: $font-weight-semi-bold;

			&:hover {
				color: $color-orange;
			}
		}
	}
</style>
