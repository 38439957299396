<template>
	<CRow
		:gutters="false"
		class="form-group"
	>
		<CCol md="8">
			<h4>
				{{ title }}
			</h4>
			<div class="typo-body-2 color-black-45">
				{{ description }}
			</div>
		</CCol>
		<CCol md="4" class="text-right">
			<router-link
				:to="{
					name: 'PaymentCreditCardBankChannelCreate',
					params: { id: $route.params.id, methodId: $route.params.methodId }
				}"
				class="btn btn-secondary"
			>
				<CIcon class="mr-2" name="cil-plus" /> Create channel
			</router-link>
		</CCol>
		<CCol md="12">
			<BaseNoItemBanner
				v-if="!channels.length"
				class="mt-4"
				text="There are no channels."
			/>
			<template v-else>
				<PaymentBankChannelsInstallmentTable
					v-if="isInstallmentType"
					:channels="channels"
				/>
				<PaymentBankChannelsTable
					v-else
					:channels="channels"
				/>
			</template>
			<hr class="my-5">
		</CCol>
	</CRow>
</template>

<script>
import PaymentBankChannelsTable from '@/components/PaymentBankChannelsTable.vue';
import PaymentBankChannelsInstallmentTable from '@/components/PaymentBankChannelsInstallmentTable.vue';

export default {
	name: 'PaymentBankChannels',

	components: {
		PaymentBankChannelsTable,
		PaymentBankChannelsInstallmentTable,
	},
	props: {
		channels: {
			type: Array,
			default: () => [],
		},
		isInstallmentType: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		title() {
			return this.isInstallmentType
				? 'Channels and 0% installment plan'
				: 'Channels';
		},
		description() {
			return this.isInstallmentType
				? 'Manage 0% installment plan and set available SKUs for 0% plan for each channels. Have to create channel first before create 0% instllment plan and set available SKU.'
				: 'The channels below will be shown underneath the other banks options in the checkout process. Each channel will use the same general setting.';
		},
	},
};
</script>

<style lang="scss" scoped>
	::v-deep .table-channels {
		color: $color-black-90;

		tbody {
			box-shadow: 0 0 0 1px $color-gray-400;
			border-radius: 4px;
		}

		td,
		th {
			padding: rem(12);
		}

		tr {
			> td, > th {
				padding-left: rem(16);
			}
		}

		.channel-name-th {
			font-size: rem($font-size-helper-text);
		}

		.icon-edit-channel {
			@include typo-body-2;

			cursor: pointer;
			color: $color-black-45;
			font-weight: $font-weight-semi-bold;

			&:hover {
				color: $color-orange;
			}
		}
	}
</style>
